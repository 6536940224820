<template>
  <div class="container">
    <div class="row justify-content-center align-items-center vh-100">
      <div class="col-12 col-md-6">
        <h1 class="text-center mb-5">Email checker</h1>
        <b-form-group label="email" >
          <b-form-input
              v-model="email"
          />
          <div id="email-warning-container" :class="{ view: suggestedEmail.full && !forceCloseModal }" >
            <button id="warning-dismiss" @click="dismissWarning"> X </button>
            <span id="warning" @click="setEmailToSuggested">
              <span id="warning-text">Did you mean</span>
              <span id="warning-email">{{ suggestedEmail.full }}?</span>
            </span>
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { run } from '@zootools/email-spell-checker'

export default {
  name: 'EmailChecker',
  data() {
    return {
      email: '',
      suggestedEmail: {},
      forceCloseModal: false,
      timer: null
    }
  },
  methods: {
    setEmailToSuggested() {
      this.forceCloseModal = true
      this.email = this.suggestedEmail.full
      setTimeout(() => {
        this.suggestedEmail = {}
        this.forceCloseModal = false
      }, 600)
    },
    dismissWarning(e) {
      e.preventDefault()
      this.forceCloseModal = true
      setTimeout(() => {
        this.suggestedEmail = {}
        this.forceCloseModal = false
      }, 600)
    }
  },
  watch: {
    email(val) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        const suggested = run({
          email: val
        })
        if (suggested) {
          this.suggestedEmail = suggested
        } else {
          this.forceCloseModal = true
          setTimeout(() => {
            this.suggestedEmail = {}
            this.forceCloseModal = false
          }, 600)
        }
      }, 500)
    }
  },
}
</script>

<style>
#email-warning-container {
  display: flex;
  align-items: center;
  height: 0px;
  opacity: 0;
  width: 100%;
  background-color: #fee7ba;
  transition: height, opacity 1.5s, padding, margin-bottom, 0.5s;
  margin-bottom: 0px;
  border-radius: 5px;
}

#warning-dismiss {
  background-color: transparent;
  border: 0;
  margin-right: 5px;
}

#email-warning-container.view {
  height: 100%;
  opacity: 100%;
  margin-bottom: 10px;
  padding: 6px;
}

#warning-dismiss {
  cursor: pointer;
}

#warning {
  cursor: pointer;
  display: flex;
  gap: 3px;
}

#warning-email {
  text-decoration: underline;
}
</style>
